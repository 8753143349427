import React, { useState } from "react";

import Button from "./../components/Button";

const Section9 = () => {
  const [slider_num, setslider_num] = useState(1);
  return (
    <div className="section_main section9">
      <div className="section9_main">
        <img
          className="bg show_desktop"
          src="https://modernizingtrends.blr1.cdn.digitaloceanspaces.com/Lunos/FACT/section9/Mask%20group.svg"
        />

        <img
          className="title show_desktop"
          src="https://modernizingtrends.blr1.cdn.digitaloceanspaces.com/Lunos/FACT/section9/Frame%201437254145.svg"
        />
        <img
          className="title show_mobile"
          src="https://modernizingtrends.blr1.cdn.digitaloceanspaces.com/Lunos/mobile/Frame%201437254145.svg"
        />
        <div className="slider">
          <div className="slider_txt">
            <div className="slider_controlls">
              <div className="slider_main">
                <div className="txt">
                  <span className="active">0{slider_num}</span>
                  <span> / 02</span>
                </div>
                <div className="arrows">
                  <svg
                    className="left"
                    xmlns="http://www.w3.org/2000/svg"
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                    onClick={() => {
                      setslider_num((prev) => (prev == 1 ? 2 : 1));
                    }}
                  >
                    <path
                      d="M17.7072 20.2932C18.0982 20.6842 18.0982 21.3163 17.7072 21.7073C17.5122 21.9023 17.2562 22.0003 17.0002 22.0003C16.7442 22.0003 16.4882 21.9023 16.2933 21.7073L7.29325 12.7073C6.90225 12.3163 6.90225 11.6842 7.29325 11.2933L16.2933 2.29325C16.6842 1.90225 17.3162 1.90225 17.7072 2.29325C18.0982 2.68425 18.0982 3.31631 17.7072 3.70731L9.41422 12.0003L17.7072 20.2932Z"
                      fill="#334153"
                    />
                  </svg>
                  <svg
                    className="right"
                    xmlns="http://www.w3.org/2000/svg"
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                    onClick={() => {
                      setslider_num((prev) => (prev == 2 ? 1 : 2));
                    }}
                  >
                    <path
                      d="M17.7072 20.2932C18.0982 20.6842 18.0982 21.3163 17.7072 21.7073C17.5122 21.9023 17.2562 22.0003 17.0002 22.0003C16.7442 22.0003 16.4882 21.9023 16.2933 21.7073L7.29325 12.7073C6.90225 12.3163 6.90225 11.6842 7.29325 11.2933L16.2933 2.29325C16.6842 1.90225 17.3162 1.90225 17.7072 2.29325C18.0982 2.68425 18.0982 3.31631 17.7072 3.70731L9.41422 12.0003L17.7072 20.2932Z"
                      fill="#334153"
                    />
                  </svg>
                </div>
              </div>
              <div className="slider_bar">
                <div
                  style={{
                    transform: `translateX(${slider_num == 2 ? 100 : 0}%)`,
                  }}
                ></div>
              </div>
            </div>
            <div className="slider_title">
              <div
                style={{
                  transform: `translateX(${slider_num == 2 ? -50 : 0}%)`,
                }}
              >
                <img
                  className="img1"
                  src="https://modernizingtrends.blr1.cdn.digitaloceanspaces.com/Lunos/FACT/section9/slide_txt.svg"
                />
                <img
                  className="img1"
                  src="https://modernizingtrends.blr1.cdn.digitaloceanspaces.com/Lunos/FACT/section9/slide_txt.svg"
                />
              </div>

              <Button
                {...{
                  txt: "Buy Now",
                  cl: "contact_us no_shadow show_desktop",
                  gotocontact: true,
                }}
              />
            </div>
          </div>
          <div className="slider_img show_desktop">
            <div
              style={{
                transform: `translateX(${slider_num == 2 ? -50 : 0}%)`,
              }}
            >
              <img
                className="img1"
                src="https://modernizingtrends.blr1.cdn.digitaloceanspaces.com/Lunos/FACT/section9/Group%203264.svg"
              />
              <img
                className="img2"
                src="https://modernizingtrends.blr1.cdn.digitaloceanspaces.com/Lunos/FACT/section9/Group%203264.svg"
              />
            </div>
          </div>
          <div className="slider_img show_mobile">
            <div
              style={{
                transform: `translateX(${slider_num == 2 ? -50 : 0}%)`,
              }}
            >
              <img
                className="img1"
                src="https://modernizingtrends.blr1.cdn.digitaloceanspaces.com/Lunos/mobile/Group%203275.svg"
              />
              <img
                className="img2"
                src="https://modernizingtrends.blr1.cdn.digitaloceanspaces.com/Lunos/mobile/Group%203275.svg"
              />
              {/* <img
                className="img2"
                src="https://modernizingtrends.blr1.cdn.digitaloceanspaces.com/Lunos/FACT/section9/Group%203264.svg"
              /> */}
            </div>
          </div>
          <Button
            {...{
              txt: "Buy Now",
              cl: "contact_us no_shadow show_mobile",
              gotocontact: true,
            }}
          />
        </div>
      </div>
    </div>
  );
};

export default Section9;
