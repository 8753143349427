import React, { useEffect, useLayoutEffect, useState } from "react";

const Section5 = () => {
  const [accordian_active, setaccordian_active] = useState("01");
  const [height, setheight] = useState([0, 0, 0]);
  useLayoutEffect(() => {
    setheight(
      height.map(
        (el, i) =>
          document.getElementById("accordian_body_inner" + i).scrollHeight
      )
    );
  }, []);
  // console.log(height);

  return (
    <div className="section_main section5">
      <img
        className="bg"
        src="https://modernizingtrends.blr1.cdn.digitaloceanspaces.com/Lunos/section5/Mask%20group.svg"
      />
      <div className="section5_main">
        <img
          className="img1 show_desktop"
          src="https://modernizingtrends.blr1.cdn.digitaloceanspaces.com/Lunos/section5/Group%203249.svg"
        />
        <img
          className="img1 show_mobile"
          src="https://modernizingtrends.blr1.cdn.digitaloceanspaces.com/Lunos/mobile/seciton5/Group%203249.svg"
        />

        <div className="divider">
          <div></div>
        </div>
        <div className="solution_main">
          <img
            className="solutions show_desktop"
            src="https://modernizingtrends.blr1.cdn.digitaloceanspaces.com/Lunos/section5/SOLUTIONS.svg"
          />
          <img
            className="solutions show_mobile"
            src="https://modernizingtrends.blr1.cdn.digitaloceanspaces.com/Lunos/mobile/seciton5/SOLUTIONS.svg"
          />
        </div>
        <img
          className="question show_desktop"
          src="https://modernizingtrends.blr1.cdn.digitaloceanspaces.com/Lunos/section5/What%20problems%20does%20it%20solve_.svg"
        />
        <img
          className="question show_mobile"
          src="https://modernizingtrends.blr1.cdn.digitaloceanspaces.com/Lunos/mobile/seciton5/What%20problems%20does%20it%20solve_.svg"
        />
        <div className="accord_main">
          <div
            className="left"
            style={{
              backgroundImage:
                "url(https://modernizingtrends.blr1.cdn.digitaloceanspaces.com/Lunos/section5/smokestacks-against-a-clear-sky-industrial-landscape%201.svg)",
            }}
          >
            {/* <img className="accord_img" src="" /> */}
          </div>
          <div className="right">
            {[
              {
                no: "01",
                title: "Air Pollution",
                desc: "Air pollution seeps into our homes, posing health risks to us and our families, especially in urban areas. Vehicle emissions, industrial activities and construction projects contribute to poor AQI (air quality index) and affect our IAQ (indoor air quality), leading to respiratory issues and allergies. Insufficient ventilation aggravates the problem, highlighting the urgent need for solutions to safeguard our living and working spaces.",
              },
              {
                no: "02",
                title: "Construction dust",
                desc: "Air pollution seeps into our homes, posing health risks to us and our families, especially in urban areas. Vehicle emissions, industrial activities and construction projects contribute to poor AQI (air quality index) and affect our IAQ (indoor air quality), leading to respiratory issues and allergies. Insufficient ventilation aggravates the problem, highlighting the urgent need for solutions to safeguard our living and working spaces.",
              },
              {
                no: "03",
                title: "Poor room ventilation",
                desc: "Air pollution seeps into our homes, posing health risks to us and our families, especially in urban areas. Vehicle emissions, industrial activities and construction projects contribute to poor AQI (air quality index) and affect our IAQ (indoor air quality), leading to respiratory issues and allergies. Insufficient ventilation aggravates the problem, highlighting the urgent need for solutions to safeguard our living and working spaces.",
              },
            ].map(({ no, title, desc }, i) => (
              <div
                className={
                  "accordian_click" + (no == accordian_active ? " active" : "")
                }
                onClick={() => setaccordian_active(no)}
              >
                <div className="accordian_collect">
                  <div className="accordian_no">{no}</div>
                  <div className="accordian_data">
                    <div className="accordian_title">{title}</div>
                    <div
                      className="accordian_body"
                      style={
                        no == accordian_active
                          ? { height: height[i] }
                          : { height: 0 }
                      }
                      // style={}
                      // id={"accordian_body_outer" + i}
                    >
                      <div id={"accordian_body_inner" + i}>{desc}</div>
                    </div>
                  </div>
                  <div className="arrow">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="42"
                      height="43"
                      viewBox="0 0 42 43"
                      fill="none"
                    >
                      <circle
                        cx="21"
                        cy="21.5"
                        r="21"
                        transform="rotate(-180 21 21.5)"
                        fill="white"
                      />
                      <path
                        d="M29.2932 17.7928C29.6842 17.4018 30.3163 17.4018 30.7073 17.7928C30.9023 17.9878 31.0003 18.2438 31.0003 18.4998C31.0003 18.7558 30.9023 19.0118 30.7073 19.2067L21.7073 28.2068C21.3163 28.5978 20.6842 28.5978 20.2933 28.2068L11.2932 19.2067C10.9022 18.8158 10.9022 18.1838 11.2932 17.7928C11.6842 17.4018 12.3163 17.4018 12.7073 17.7928L21.0003 26.0858L29.2932 17.7928Z"
                        fill="url(#paint0_linear_4018_685)"
                      />
                      <defs>
                        <linearGradient
                          id="paint0_linear_4018_685"
                          x1="65.8222"
                          y1="33.2852"
                          x2="65.0641"
                          y2="14.5825"
                          gradientUnits="userSpaceOnUse"
                        >
                          <stop stop-color="#239BDE" />
                          <stop offset="1" stop-color="#134F87" />
                        </linearGradient>
                      </defs>
                    </svg>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Section5;
