import React, { useState } from "react";

const img_group = [
  "https://modernizingtrends.blr1.cdn.digitaloceanspaces.com/Lunos/section6/cards/c1.svg",
  "https://modernizingtrends.blr1.cdn.digitaloceanspaces.com/Lunos/section6/cards/c2.svg",
  "https://modernizingtrends.blr1.cdn.digitaloceanspaces.com/Lunos/section6/cards/c3.svg",
  "https://modernizingtrends.blr1.cdn.digitaloceanspaces.com/Lunos/section6/cards/c4.svg",
  "https://modernizingtrends.blr1.cdn.digitaloceanspaces.com/Lunos/section6/cards/c5.svg",
  "https://modernizingtrends.blr1.cdn.digitaloceanspaces.com/Lunos/section6/cards/c6.svg",
];

const CardSlider = () => {
  const [slider_num, setslider_num] = useState(1);
  return (
    <div className="card_slider">
      <div className="slider_controlls">
        <div className="slider_main">
          <div className="txt">
            <span className="active">0{slider_num}</span>
            <span> / 02</span>
          </div>
          <div className="arrows">
            <svg
              className="left"
              xmlns="http://www.w3.org/2000/svg"
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
              onClick={() => {
                setslider_num((prev) => (prev == 1 ? 2 : 1));
              }}
            >
              <path
                d="M17.7072 20.2932C18.0982 20.6842 18.0982 21.3163 17.7072 21.7073C17.5122 21.9023 17.2562 22.0003 17.0002 22.0003C16.7442 22.0003 16.4882 21.9023 16.2933 21.7073L7.29325 12.7073C6.90225 12.3163 6.90225 11.6842 7.29325 11.2933L16.2933 2.29325C16.6842 1.90225 17.3162 1.90225 17.7072 2.29325C18.0982 2.68425 18.0982 3.31631 17.7072 3.70731L9.41422 12.0003L17.7072 20.2932Z"
                fill="#334153"
              />
            </svg>
            <svg
              className="right"
              xmlns="http://www.w3.org/2000/svg"
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
              onClick={() => {
                setslider_num((prev) => (prev == 2 ? 1 : 2));
              }}
            >
              <path
                d="M17.7072 20.2932C18.0982 20.6842 18.0982 21.3163 17.7072 21.7073C17.5122 21.9023 17.2562 22.0003 17.0002 22.0003C16.7442 22.0003 16.4882 21.9023 16.2933 21.7073L7.29325 12.7073C6.90225 12.3163 6.90225 11.6842 7.29325 11.2933L16.2933 2.29325C16.6842 1.90225 17.3162 1.90225 17.7072 2.29325C18.0982 2.68425 18.0982 3.31631 17.7072 3.70731L9.41422 12.0003L17.7072 20.2932Z"
                fill="#334153"
              />
            </svg>
          </div>
        </div>
        <div className="slider_bar">
          <div
            style={{
              transform: `translateX(${slider_num == 2 ? 100 : 0}%)`,
            }}
          ></div>
        </div>
      </div>
      <div className="img_group">
        {img_group.map((el) => (
          <div className="cards">
            <img src={el} />
          </div>
        ))}
      </div>
    </div>
  );
};

export default CardSlider;
