import React, { useState } from "react";
import Button from "./../components/Button";

const CustInput = ({ txt = "" }) => {
  return <input placeholder={txt + "*"} />;
};

const Section10 = () => {
  const [dropdownopen, setdropdownopen] = useState(false);
  const [dropdownselect, setdropdownselect] = useState("");
  return (
    <div
      className="section_main section10"
      id="contact_us"
      style={{
        backgroundImage:
          "url(https://modernizingtrends.blr1.cdn.digitaloceanspaces.com/Lunos/contactUs/Frame%201437254245.svg)",
      }}
    >
      <div className="section10_main">
        <div className="form">
          <div className="txt1">Contact Us</div>
          <div className="txt2">We are here to help you!</div>
          <div className="input_collect">
            {["Name"].map((el) => (
              <CustInput {...{ txt: el }} />
            ))}
            <div
              className="input"
              onClick={() => setdropdownopen((prev) => !prev)}
            >
              {dropdownselect ? (
                <div>{dropdownselect}</div>
              ) : (
                <div className="placeholder">I am a...</div>
              )}
              {dropdownopen && (
                <div className="option_group">
                  <div
                    className="option"
                    onClick={() => setdropdownselect("Commerical User")}
                  >
                    Commerical User
                  </div>
                  <div
                    className="option"
                    onClick={() => setdropdownselect("Residential User")}
                  >
                    Residencial User
                  </div>
                </div>
              )}
            </div>
            {["Phone number", "Location"].map((el) => (
              <CustInput {...{ txt: el }} />
            ))}
          </div>
          <Button
            {...{
              txt: "F.A.C.T. Wow! ACT Now!",
              cl: "contact_us no_shadow",
              gotocontact: true,
            }}
          />
        </div>

        <img
          className="form_img "
          src="https://modernizingtrends.blr1.cdn.digitaloceanspaces.com/Lunos/contactUs/Frame%2014220.svg"
        />

      </div>
    </div>
  );
};

export default Section10;
