import React from "react";

const Section2 = () => {
  return (
    <div className="section_main section2">
      <div className="section2_main">
        <img
          className="section_img show_desktop"
          src="https://modernizingtrends.blr1.cdn.digitaloceanspaces.com/Lunos/FACT/section2.svg"
        />
        <img
          className="section_img show_mobile"
          src="https://modernizingtrends.blr1.cdn.digitaloceanspaces.com/Lunos/mobile/block_2.svg"
        />
      </div>
    </div>
  );
};

export default Section2;
