import React, { useEffect, useLayoutEffect, useState } from "react";

import Button from "./../components/Button";
import CardSlider from "./section6/CardSlider";

const Section6 = () => {
  const [accordian_active, setaccordian_active] = useState("01");

  return (
    <div className="section_main section6">
      <img
        className="bg show_desktop"
        src="https://modernizingtrends.blr1.cdn.digitaloceanspaces.com/Lunos/section6/cards/Group%203265.svg"
      />
      <img
        className="bg show_mobile"
        src="https://modernizingtrends.blr1.cdn.digitaloceanspaces.com/Lunos/mobile/Vector%202.svg"
      />
      <div className="section6_main">
        <div className="txt">
          <img
            className="txt_img show_desktop"
            src="https://modernizingtrends.blr1.cdn.digitaloceanspaces.com/Lunos/section6/cards/Group%203266.svg"
          />
          <img
            className="txt_img show_mobile"
            src="https://modernizingtrends.blr1.cdn.digitaloceanspaces.com/Lunos/mobile/Group%203274.svg"
          />
          <div className="txt_btn show_mobile">Yes, I'm In</div>
        </div>
        <CardSlider />
      </div>
    </div>
  );
};

export default Section6;
