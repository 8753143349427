import React, { useEffect, useLayoutEffect, useRef, useState } from "react";
import logo from "../img/logo.png";
import Button from "./Button";

const Navigation = ({ setnav_height }) => {
  const nav = useRef();
  const [nav_opacity, setnav_opacity] = useState(0);

  useLayoutEffect(() => {
    window.onscroll = (e) => {
      let height = window.innerHeight;

      setnav_opacity((window.scrollY / height).toFixed(2));
    };
    setnav_height(nav.current.getBoundingClientRect().height);
  }, []);


  return (
    <div className="nav" style={{ "--bg_opacity": nav_opacity }} ref={nav}>
      <img className="logo" src={logo} />
      <Button
        {...{
          txt: "Contact Us",
          cl: "contact_us",
          gotocontact: true,
        }}
      />
    </div>
  );
};

export default Navigation;
