import React from "react";

const Button = ({ cl, txt, click, gotocontact }) => {
  return (
    <a
      {...{
        className: "btn" + (cl ? ` ${cl}` : ""),
        ...(click
          ? {
              onClick: click,
            }
          : {}),
        ...(gotocontact
          ? {
              href: "#contact-us",
            }
          : {}),
      }}
    >
      {txt}
    </a>
  );
};

export default Button;
