import React from "react";

const Section2 = () => {
  return (
    <div className="section_main section3">
      {/* <img
        className="img_stretch"
        src="https://modernizingtrends.blr1.cdn.digitaloceanspaces.com/Lunos/FACT/Section2.svg"
      /> */}
      <div
        className="section3_main"
        // style={{
        //   backgroundImage:
        //     "url(https://modernizingtrends.blr1.cdn.digitaloceanspaces.com/Lunos/FACT/Frame%201437254110.svg)",
        // }}
      >
        {/* <img
          className="img1"
          src="https://modernizingtrends.blr1.cdn.digitaloceanspaces.com/Lunos/FACT/Group%203259.svg"
        />
        <img
          className="img2"
          src="https://modernizingtrends.blr1.cdn.digitaloceanspaces.com/Lunos/FACT/Group%203260.svg"
        /> */}
        <img
          className="section_img show_desktop"
          src="https://modernizingtrends.blr1.cdn.digitaloceanspaces.com/Lunos/FACT/section3.svg"
        />
        <img
          className="section_img show_mobile"
          src="https://modernizingtrends.blr1.cdn.digitaloceanspaces.com/Lunos/mobile/block_3.svg"
        />
      </div>
    </div>
  );
};

export default Section2;
