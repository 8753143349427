import React from "react";

const Section8 = () => {
  return (
    <div className="section_main section8">
      <div className="section8_main">
        {/* <img
          className="img1"
          src="https://modernizingtrends.blr1.cdn.digitaloceanspaces.com/Lunos/FACT/Frame%201437254233.svg"
        />
        <img
          className="img2"
          src="https://modernizingtrends.blr1.cdn.digitaloceanspaces.com/Lunos/FACT/Frame%201437254231.png"
        /> */}
        <img
          className="section_img show_desktop"
          src="https://modernizingtrends.blr1.cdn.digitaloceanspaces.com/Lunos/FACT/section8.svg"
        />
        <img
          className="section_img show_mobile"
          src="https://modernizingtrends.blr1.cdn.digitaloceanspaces.com/Lunos/mobile/block_8.svg"
        />
      </div>
    </div>
  );
};

export default Section8;
