import { useState } from "react";
import Navigation from "./components/Navigation";
import Section1 from "./sections/Section1";
import Section2 from "./sections/Section2";
import Section3 from "./sections/Section3";
import Section4 from "./sections/Section4";
// import Section5 from "./sections/Section5";
// import Section6 from "./sections/Section6";
import Section7 from "./sections/Section7";
import Section8 from "./sections/Section8";
import Section9 from "./sections/Section9";
import Section5 from "./sections/Section5";
import Section6 from "./sections/Section6";
import Section10 from "./sections/Section10";

function App() {
  const [nav_height, setnav_height] = useState(0);
  // console.log(nav_height);
  return (
    <>
      <style>{`:root{--nav_height:${nav_height}px}`}</style>
      <Navigation {...{ setnav_height }} />
      <Section1 />
      <Section2 />
      <Section3 />
      <Section4 />
      <Section5 />
      <Section6 />
      <Section7 />
      <Section8 />
      <Section9 />
      <Section10 />
      <img
        src="https://modernizingtrends.blr1.cdn.digitaloceanspaces.com/Lunos/contactUs/Frame%201437254250.svg"
        style={{ width: "100%" }}
        className="show_desktop"
      />
      <img
        src="https://modernizingtrends.blr1.cdn.digitaloceanspaces.com/Lunos/mobile/footer123.svg"
        style={{ width: "100%" }}
        className="show_mobile"
      />
    </>
  );
}

export default App;
