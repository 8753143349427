import React from "react";
import Button from "./../components/Button";

const Section4 = () => {
  return (
    <div className="section_main section4">
      <div className="section4_main">
        <img
          className="section4_img show_mobile"
          src="https://modernizingtrends.blr1.cdn.digitaloceanspaces.com/Lunos/mobile/Mask%20group.svg"
        />
        <img
          className="img1 show_desktop"
          src="https://modernizingtrends.blr1.cdn.digitaloceanspaces.com/Lunos/FACT/Frame%201437254233.svg"
        />
        <img
          className="img1 show_mobile"
          src="https://modernizingtrends.blr1.cdn.digitaloceanspaces.com/Lunos/mobile/Frame%201437254266.svg"
        />
        {/* <img
          className="img1 "
          src="https://modernizingtrends.blr1.cdn.digitaloceanspaces.com/Lunos/FACT/Frame%201437254233.svg"
        /> */}
        <img
          className="img2 show_desktop"
          src="https://modernizingtrends.blr1.cdn.digitaloceanspaces.com/Lunos/FACT/Frame%201437254231.png"
        />
        <img
          className="img2 show_mobile"
          src="https://modernizingtrends.blr1.cdn.digitaloceanspaces.com/Lunos/mobile/Group%203273.svg"
        />
        <Button
          {...{
            txt: "I'm Interested",
            cl: "contact_us no_shadow",
            gotocontact: true,
          }}
        />
      </div>
    </div>
  );
};

export default Section4;
