import React from "react";
import Button from "./../components/Button";

const Section7 = () => {
  return (
    <div className="section_main section7">
      <div className="section7_main">
        {/* <img
          className="img1"
          src="https://modernizingtrends.blr1.cdn.digitaloceanspaces.com/Lunos/FACT/Frame%201437254233.svg"
        />
        <img
          className="img2"
          src="https://modernizingtrends.blr1.cdn.digitaloceanspaces.com/Lunos/FACT/Frame%201437254231.png"
        /> */}
        <img
          className="section_img show_desktop"
          src="https://modernizingtrends.blr1.cdn.digitaloceanspaces.com/Lunos/FACT/section7.svg"
        />
        <img
          className="section_img show_mobile"
          src="https://modernizingtrends.blr1.cdn.digitaloceanspaces.com/Lunos/mobile/block_7.svg"
        />
      </div>
    </div>
  );
};

export default Section7;
